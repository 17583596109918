"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ShippingAvailability;
(function (ShippingAvailability) {
    ShippingAvailability[ShippingAvailability["Available"] = 0] = "Available";
    ShippingAvailability[ShippingAvailability["ComingSoon"] = 1] = "ComingSoon";
    ShippingAvailability[ShippingAvailability["StockError"] = 2] = "StockError";
    ShippingAvailability[ShippingAvailability["AvailableWithActualStockAndPoStock"] = 3] = "AvailableWithActualStockAndPoStock";
    ShippingAvailability[ShippingAvailability["AvailableOnlyWithPoStock"] = 4] = "AvailableOnlyWithPoStock";
    ShippingAvailability[ShippingAvailability["PartiallyAvailableStock"] = 5] = "PartiallyAvailableStock";
})(ShippingAvailability = exports.ShippingAvailability || (exports.ShippingAvailability = {}));
