"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const queryString = require('query-string');
class SearchHelper {
    static getQueryStringFilters(facets) {
        var filters = new Map();
        let query = queryString.parse(location.search);
        for (const facet of facets) {
            if (query[facet] !== undefined && query[facet] != '') {
                filters.set(facet, query[facet].split("||"));
            }
        }
        return filters;
    }
    static pushToHistory(filterName, facetsSelected) {
        let query = queryString.parse(location.search);
        let baseUrl = location.protocol + '//' + location.host + location.pathname;
        const modifiedQuery = Object.assign(Object.assign({}, query), { [filterName]: facetsSelected.join('||') });
        let url = queryString.stringifyUrl({
            url: baseUrl,
            query: modifiedQuery,
            skipEmptyString: true
        });
        window.history.pushState({}, '', url);
    }
}
exports.default = SearchHelper;
