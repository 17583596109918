"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
exports.ImgRatio = {
    DEFAULT: "4:3",
    LANDSCAPE: "21:9"
};
const ImageWithRatio = (props) => {
    let ratioVariantClass = "o-ratio";
    switch (props.ratio) {
        case exports.ImgRatio.DEFAULT:
        case exports.ImgRatio.LANDSCAPE:
            ratioVariantClass += ` o-ratio--${props.ratio}`;
            break;
    }
    /*RENDER*/
    return (React.createElement("div", { className: ratioVariantClass },
        React.createElement("a", { href: props.imgLink },
            React.createElement("picture", { className: `o-ratio__content ${props.componentElementClass}` },
                React.createElement("img", { className: "o-fit", src: props.imgSrc, alt: props.imgAlt })))));
};
exports.default = ImageWithRatio;
