"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const ReactDOM = __importStar(require("react-dom"));
const CheckoutPageAddress_1 = require("./CheckoutPageAddress");
const CheckoutPageShipping_1 = require("./CheckoutPageShipping");
const CheckoutPageReview_1 = require("./CheckoutPageReview");
const element = document.getElementById("root-checkout-address");
const elementShipping = document.getElementById("root-checkout-shipping");
const elementReview = document.getElementById("root-checkout-review");
if (element) {
    ReactDOM.render(React.createElement(CheckoutPageAddress_1.CheckoutPageAddress, { dataInitial: element.dataset.initial }), element);
}
if (elementShipping) {
    ReactDOM.render(React.createElement(CheckoutPageShipping_1.CheckoutPageShipping, { dataInitial: elementShipping.dataset.initial }), elementShipping);
}
if (elementReview) {
    ReactDOM.render(React.createElement(CheckoutPageReview_1.CheckoutPageReview, { dataInitial: elementReview.dataset.initial }), elementReview);
}
