"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SearchNumberOfResults_1 = require("../../../../../Foundations/React/Facetting/SearchNumberOfResults");
class GeneralSearchNumberOfResults extends SearchNumberOfResults_1.SearchNumberOfResults {
    constructor(props) {
        super(props);
    }
    renderComponent() {
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "c-product-overview__info" }, this.getNumberOfResultsText())));
    }
}
exports.GeneralSearchNumberOfResults = GeneralSearchNumberOfResults;
