"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SearchSelectedFacets_1 = require("../../../../../Foundations/React/Facetting/SearchSelectedFacets");
class ProductCategorySearchSelectedFacets extends SearchSelectedFacets_1.SearchSelectedFacets {
    constructor(props) {
        super(props);
    }
    renderComponent() {
        return (React.createElement(React.Fragment, null, this.valuesInOrder.map((keyValue) => (React.createElement(React.Fragment, { key: "pcssf-" + keyValue.key },
            React.createElement("div", { className: "c-tag" },
                React.createElement("span", { className: "c-tag__label" }, keyValue.value),
                React.createElement("button", { className: "c-btn c-btn--icon c-tag__close", type: "button", "aria-label": "close", onClick: () => this.onRemoveFilter(keyValue.key) },
                    React.createElement("svg", { className: "c-svg ", width: "11", height: "11" },
                        React.createElement("use", { xlinkHref: "#close" })))))))));
    }
}
exports.ProductCategorySearchSelectedFacets = ProductCategorySearchSelectedFacets;
