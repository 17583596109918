"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const ReactDOM = __importStar(require("react-dom"));
const ProductCategorySearch_1 = require("./ProductCategorySearch");
var elt = document.getElementById("root-productcategory-search");
if (elt != null) {
    ReactDOM.render(React.createElement(ProductCategorySearch_1.ProductCategorySearch, { dataInitial: elt.dataset.initial, autoFetch: false }), elt);
}
