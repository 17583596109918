"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class StringHelper {
    static IsNullOrEmpty(text) {
        // == so it covers undefined as well!
        return text == null || text === '';
    }
    static PhoneNumberPattern() {
        return new RegExp("^(\\+?)([0-9]*)$");
    }
}
exports.default = StringHelper;
