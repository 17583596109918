"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
class ShippingTypeSelector extends React.Component {
    constructor(props) {
        super(props);
        this.selectorClicked = () => {
            if (!this.props.isSelected && this.props.typeSelectedCallback !== null) {
                this.props.typeSelectedCallback(this.props.selectorId);
            }
        };
        this.renderCheckMark = () => {
            if (this.props.isSelected) {
                return (React.createElement("div", { className: "c-shipping-type__check" },
                    React.createElement("svg", { className: "c-svg", width: "60", height: "45" },
                        React.createElement("use", { xlinkHref: "#check" }))));
            }
        };
    }
    render() {
        return (React.createElement("div", { className: `c-shipping-type ${this.props.isSelected ? "is-active" : ""}`, onClick: this.selectorClicked },
            React.createElement("div", { className: "c-shipping-type__body" },
                React.createElement("h3", { className: "u-txt-upper" }, this.props.selectorTitle.toUpperCase()),
                React.createElement("p", null, this.props.selectorDescription)),
            this.renderCheckMark()));
    }
}
exports.ShippingTypeSelector = ShippingTypeSelector;
