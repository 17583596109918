"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
class SearchTextSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: this.props.defaultSearchText
        };
        this.searchTextChanged = this.searchTextChanged.bind(this);
        this.searchButtonClicked = this.searchButtonClicked.bind(this);
        this.onSearchTextKeyPress = this.onSearchTextKeyPress.bind(this);
    }
    searchTextChanged(event) {
        this.setState({
            searchText: event.target.value
        });
    }
    searchButtonClicked() {
        this.props.onSearchTextChanged(this.state.searchText);
    }
    onSearchTextKeyPress(e) {
        if (e.key === "Enter") {
            this.searchButtonClicked();
            e.preventDefault();
        }
    }
}
exports.SearchTextSearch = SearchTextSearch;
