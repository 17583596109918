"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
class CheckoutAddressBlock extends React.Component {
    constructor(props) {
        super(props);
        this.addressClicked = () => {
            if (!this.props.isActive && this.props.addressSelectedCallback != null) {
                this.props.addressSelectedCallback(this.props.address.AddressId);
            }
        };
    }
    render() {
        return (React.createElement("address", { className: `c-address ${this.props.isActive ? "is-active" : ""}`, onClick: this.addressClicked },
            React.createElement("h3", null, this.props.address.AddressName),
            React.createElement("div", { className: "c-address__body c-rich-text" },
                React.createElement("div", null, this.props.address.AddressPersonalName),
                React.createElement("div", null, this.props.address.Street),
                React.createElement("div", { className: "c-address__bottom" },
                    React.createElement("div", null,
                        this.props.address.PostalCode,
                        " ",
                        this.props.address.City),
                    React.createElement("div", null, this.props.address.County),
                    React.createElement("div", null, this.props.address.Country),
                    React.createElement("div", { className: "u-padding-top-small" }),
                    React.createElement("div", null, this.props.address.AddressPhone))),
            React.createElement("div", { className: "c-address__check" },
                React.createElement("svg", { className: "c-svg", width: "60", height: "45" },
                    React.createElement("use", { xlinkHref: "#check" })))));
    }
}
exports.CheckoutAddressBlock = CheckoutAddressBlock;
